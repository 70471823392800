import React, { useContext, useEffect, useState } from 'react'
import Manager, { GetUserInfo } from '../helpers/UserManager';
import SubMenuCFDI from './SubMenuCFDI'
import SubMenuNomina from './SubMenuNomina'
import SubMenuRecepcion from './SubMenuRecepcion'
import Bitacora from './Bitacora';
import { UserContext } from '../contexts/UserContext';

const views =
{
    MENU_EMISION: 10,
    MENU_CFDI: 20,
    MENU_NOMINA: 30,
    MENU_RECEPCION: 40,
    MENU_BITACORA: 50,
}

const MainMenu = (props) => {
    const { user } = useContext(UserContext);
    const [state, setState] = useState({
        menuEmision: true,
        menuRecepcion: true,
        activeView: views.MENU_EMISION,
        activeMenu: views.MENU_EMISION,
    });

    useEffect(() => {
        setMenu();
    }, []);

    const setMenu = () => {
        Manager.getUser().then(async userManaged => {
            let opcion = user.response.option;

            setState({
                ...this,
                menuEmision: opcion === 'EMISION' || opcion === 'TODOS' ? true : false,
                menuRecepcion: opcion === 'RECEPCION' || opcion === 'TODOS' ? true : false,
                activeView: opcion === 'RECEPCION' ? views.MENU_RECEPCION : views.MENU_EMISION,
            });
        });
    }

    const getSelected = (equalsTo) => {
        let who = state.activeMenu;
        return who === equalsTo ? "selectedMenu" : "unselectedMenu";
    }

    const changeActiveView = (newActiveView) => {
        setState(
            {
                ...this,
                activeView: newActiveView,
                activeMenu: (newActiveView === views.MENU_CFDI || newActiveView === views.MENU_NOMINA) ? views.MENU_EMISION : newActiveView
            });
    }

    return (
        <div id='mainView'>
            <header>
                <div className="container logo-nav-container">
                    <a href='https://www.sovos.com'><div id='jirafitaBanner' /></a>
                    <nav className="mainMenu">
                        <ul>
                            <li>
                                <div>
                                    <a href="#" id={getSelected(views.MENU_EMISION)}>EMISIÓN</a>
                                    <ul className="submenuItem">
                                        <li><a href="#" onClick={() => changeActiveView(views.MENU_CFDI)}>CFDI</a></li>
                                        {user.response.profileId == 1 && <li><a href="#" onClick={() => changeActiveView(views.MENU_NOMINA)}>Nómina</a></li>}
                                    </ul>
                                </div>
                            </li>
                            <li><a href='#' id={getSelected(views.MENU_RECEPCION)} onClick={() => changeActiveView(views.MENU_RECEPCION)}>RECEPCIÓN</a></li>
                            <li><a href='#' id={getSelected(views.MENU_BITACORA)} onClick={() => changeActiveView(views.MENU_BITACORA)}>BITÁCORA</a></li>
                            <li><a href='/logout'>SALIR</a></li>
                            <li><label>( {user.response.userName} )</label></li>
                        </ul>
                    </nav>
                </div>
            </header>

            <hr />

            <main id='mainContent'>
                <div>
                    {state.activeView === views.MENU_CFDI && <SubMenuCFDI></SubMenuCFDI>}
                    {state.activeView === views.MENU_NOMINA && <SubMenuNomina></SubMenuNomina>}
                    {state.activeView === views.MENU_RECEPCION && <SubMenuRecepcion></SubMenuRecepcion>}
                    {state.activeView === views.MENU_BITACORA && <Bitacora></Bitacora>}
                </div>
            </main>
        </div>);
}

export default MainMenu
