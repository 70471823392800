import React, { useState } from 'react'
import Dashboard from './Dashboard';
import * as QSDB from '../helpers/constantes'

const VIEW_DESCRIPTION = "Emisión/CFDI";

const views =
{
    BUSQUEDAS: 10,
    CONCILIACIONES: 11,
    METADATA_EMISION: 12
}

const descriptions =
{
    BUSQUEDAS: "Búsquedas",
    CONCILIACIONES: "Conciliaciones",
    METADATA_EMISION: "Metadatos SAT"
}

const SubMenuCFDI = () => {

    const [state, setState] = useState({activeView: views.BUSQUEDAS});

    const changeActiveView = (newActiveView) =>
    {
        setState({ activeView: newActiveView });
    }

    return (
        <div>
            <div className="dashboardOption">
                <ul>
                    <li><a href='#' onClick={() => changeActiveView(views.BUSQUEDAS)}>{descriptions.BUSQUEDAS}</a></li>
                    <li><a href='#' onClick={() => changeActiveView(views.METADATA_EMISION)}>{descriptions.METADATA_EMISION}</a></li>
                    <li><a href='#' onClick={() => changeActiveView(views.CONCILIACIONES)}>{descriptions.CONCILIACIONES}</a></li>
                </ul>
            </div>

            <div>
                {views.BUSQUEDAS === state.activeView ? <Dashboard title={VIEW_DESCRIPTION} subtitle={descriptions.BUSQUEDAS} idDashboard={QSDB.BUSQUEDAS_DB}></Dashboard> : <div></div>}
                {views.METADATA_EMISION === state.activeView ? <Dashboard title={VIEW_DESCRIPTION} subtitle={descriptions.METADATA_EMISION} idDashboard={QSDB.METADATA_EMISION_DB}></Dashboard> : <div></div>}
                {views.CONCILIACIONES === state.activeView ? <Dashboard title={VIEW_DESCRIPTION} subtitle={descriptions.CONCILIACIONES} idDashboard={QSDB.CONCILIACIONES_DB}></Dashboard> : <div></div>}
            </div>
        </div>
    );
}

export default SubMenuCFDI;