import React from 'react'
import logo from '../resources/images/sovos-logo.svg';

const Modal = ({ setIsOpen }) => {
  return (
    <>
    <div className="darkBG" onClick={() => setIsOpen(false)} />
    <div className="centered">
      <div className="modal">
        <div className="modalHeader">
          <h3 className="heading">Cerrando sesión ...</h3>
        </div>
        <div className="modalContent">
          <div>
          <img className='imgModal' src={logo} alt="FCC Logo" />
          </div>
        <h4> Para terminar su sesión por completo por favor cierre su navegador.</h4>
        </div>
        <div className="modalActions">
          <div className="actionsContainer">
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default Modal;