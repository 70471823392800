import React, { useState } from 'react'
import Dashboard from './Dashboard';
import * as QSDB from '../helpers/constantes'

const VIEW_DESCRIPTION = "Recepción";

const views =
{
    METADATA_RECEPCION: 20
}

const descriptions =
{
    METADATA_RECEPCION: "Metadatatos SAT",
}

const SubMenuRecepcion = () => {
    const [state, setState] = useState({ activeView: views.METADATA_RECEPCION });

    const changeActiveView = (newActiveView) => {
        setState({ activeView: newActiveView });
    }

    return (
        <div id='subView'>
            <div className="dashboardOption">
                <ul>
                    <li className=""><a href='#' onClick={() => changeActiveView(views.METADATA_RECEPCION)}>{descriptions.METADATA_RECEPCION}</a></li>
                </ul>
            </div>

            <div>
                {views.METADATA_RECEPCION === state.activeView ? <Dashboard title={VIEW_DESCRIPTION} subtitle={descriptions.METADATA_RECEPCION} idDashboard={QSDB.METADATA_RECEPCION_DB}></Dashboard> : <div></div>}
            </div>

        </div>
    );
}

export default SubMenuRecepcion;