import axios from "axios";
import { API_URL } from "../helpers/constantes";

export const http = axios.create({
    baseURL: API_URL
})

http.interceptors.request.use(config => {
    const token = localStorage.getItem('token');

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['IdOrganizacion'] = localStorage.getItem('idOrganizacion');;
        config.headers['IdCuenta'] = localStorage.getItem('idCuenta');;
        config.headers['IdUsuario'] = localStorage.getItem('idUsuario');;
        config.headers['Username'] = localStorage.getItem('username');;
    }

    return config;
}, error => {
    Promise.reject(error);
})