import React, { useState } from 'react';
import '../resources/App.css';
import Routes from './Routes'
import { UserContext } from '../contexts/UserContext';

const App = () => {
    const [user, setUser] = useState(false);

    return (
        <UserContext.Provider value={{ user, setUser }}>
            <Routes />
        </UserContext.Provider>
    );
}

export default App;
