import React, { useState } from 'react'
import { http } from '../http/Http';

const Bitacora = () => {

    const [state, setState] = useState({ items: null });

    const getData = () => {
        http.get('/events').then(response => {
            processResponse(response);
        }).catch(error => {
            setState({items: null});
            console.log(`No sabemos que pasó, pero de todos modos falló -> ${error.response.status} ${error.response.data}`);
        });
    }

    const processResponse = (response) => {
        switch (response.status) {
            case 200:
                setState({items: response.data});
                return response.data;
            case 401:
                setState({items: null});
                alert('Por seguridad debes salir de la aplicación y volverte a firmar ya que tu periodo de inactividad ha sido demasiado largo');
                console.log("El API rechazó la solicitud");
                break;
            default:
                setState({items: null});
                alert('Error')
                console.log("No sabemos que pasó, pero de todos modos falló", response.status);
                break;
        }
    }

    return (
        <div>
            <h3>BITÁCORA</h3>
            <button onClick={() => getData()}>Consultar</button>
            {state.items != null ?
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Usuario</th>
                                <th>Fecha</th>
                                <th>Evento</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                state.items.map((eventItem, index) =>
                                    <tr key={index}>
                                        <td>{eventItem.userName}</td>
                                        <td>{eventItem.date}</td>
                                        <td>{eventItem.action}</td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div> : <div></div>}
        </div>
    );
}

export default Bitacora;