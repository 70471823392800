import React, {useContext, useEffect, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import Manager, { GetUserInfo } from '../helpers/UserManager';
import Menu from './MainMenu';
import ModalError from './ModalError';

const Home = () => {
    const [state, setState] = useState({ authorize: false });
    const { user, setUser } = useContext(UserContext);

    const authorize = async () => {
        try {
            const userManaged = await Manager.getUser();
            if (!userManaged) {
                Manager.signinRedirect();
            } else {
                if (!state.authorize) {
                    const userData = await GetUserInfo(userManaged.profile.email, userManaged.access_token);
                    if (userData === null) {
                        setState({ ...state, showErrorModal: true });
                        return;
                    }

                    setUser(userData);
                    setState({ authorize: true });
                    localStorage.setItem('token', userManaged.access_token);
                    localStorage.setItem('idOrganizacion', userData.response.companyId);
                    localStorage.setItem('idCuenta', userData.response.profileId);
                    localStorage.setItem('idUsuario', userData.response.userId);
                    localStorage.setItem('username', userData.response.userName);
                }                
                console.log('still connected!');             
            }
        }
       catch (error) {
            console.error('Error during authorization:', error);
            setState({ ...state, showErrorModal: true });
        }
    }

    useEffect(() => {
        setState({ authorize: false, showErrorModal: false });
        authorize();
        const intervalId = setInterval(() => authorize(), 300000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            {state.authorize ? <Menu /> : null}
            {state.showErrorModal ? <ModalError setIsOpen={() => setState({ ...state, showErrorModal: false })} /> : null}
        </div>
    );
}

export default Home;