import React, { useEffect, useState } from 'react';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { http } from '../http/Http';

const Dashboard = (props) => {

    const [state] = useState({
        dashboardUrl: '',
        settings: '',
        title: props.title,
        subtitle: props.subtitle,
        idDashboard: props.idDashboard
    });

    useEffect(() => {
        const getUrl = () => {
            return http.get(`/dashboards/${state.idDashboard}`)
            .then(response => {
                switch (response.status) {
                    case 200: {
                        showDashboard(response.data);
                        break;
                    }
                    case 401: {
                        alert('Por seguridad debes salir de la aplicación y volverte a firmar ya que tu periodo de inactividad ha sido demasiado largo');
                        console.log("El API rechazó la solicitud");
                        break;
                    }
                    default: {
                        console.log("No sabemos que pasó, pero de todos modos falló", response.status);
                        break;
                    }
                }
            }).catch(error => {
                console.log(`Unexpected error has ocurred -> ${error.response.status} ${error.response.data}`);
            })
        };
        getUrl();
    }, [])



    const showDashboard = (data) => {
        let today = new Date();

        document.getElementById('dashboard').innerHTML = '';

        const embedDashboard = async() => {
            const {
                createEmbeddingContext,
            } = QuickSightEmbedding;

            const embeddingContext = await createEmbeddingContext({});

            const frameOptions = {
                url: data.url,
                container: '#dashboard',
                height: "700px",
                width: "100%",
                resizeHeightOnSizeChangedEvent: true,
                withIframePlaceholder: true,
            };

            const contentOptions = {
                parameters: [],
                locale: "es-MX",
                sheetOptions: {
                    singleSheet: false,                        
                    emitSizeChangedEventOnSheetChange: false,
                },
                toolbarOptions: {
                    export: false,
                    undoRedo: false,
                    reset: false
                },
                attributionOptions: {
                    overlayContent: false,
                },
                onMessage: async (messageEvent, experienceMetadata) => {
                    switch (messageEvent.eventName) {
                        case 'MODAL_OPENED': {
                            window.scrollTo({
                                top: 0
                            });
                            break;
                        }
                        default: {
                            break;
                        }
                    }
                },
            };
            await embeddingContext.embedDashboard(frameOptions, contentOptions);
        };

        embedDashboard();
    }

    return (
        <div>
            <h3>{state.title} -&gt; {state.subtitle}</h3>
            <div id="dashboard"></div>
        </div>
    );
}

export default Dashboard;