import React from 'react'
import logo from '../resources/images/sovos-logo.svg';
import Manager from '../helpers/UserManager'

const ModalError = ({ setIsOpen }) => {
    const handleRedirect = () => {
        Manager.signoutRedirect({});
      };
    return (
      <>
      <div className="darkBG" onClick={() => setIsOpen(false)} />
      <div className="centered">
        <div className="modal">
          <div className="modalHeader">
            <h3 className="heading">Solicitud incorrecta</h3>
          </div>
          <div className="modalContent">
            <div>
            <img className='imgModal' src={logo} alt="FCC Logo" />
            </div>
          <h4>El usuario no tiene permisos para ingresar a esta aplicación, porfavor verifique sus credenciales.</h4>
          </div>
          <div className="modalActions">
            <div className="actionsContainer">
              <button onClick={handleRedirect}>Volver</button>
            </div>
          </div>
        </div>
      </div>
    </>
    );
  };
  
  export default ModalError;