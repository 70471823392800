import React, { useState } from 'react'
import Home from './Home';
import Manager from '../helpers/UserManager'
import Modal from './Modal';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    //Link
} from 'react-router-dom';

const Routes = () => {
    const [setIsOpen] = useState(false);

    const logout = () => {

        setTimeout(t => {
            Manager.getUser().then(user => {
                if (user){
                    Manager.signoutRedirect().then((res) => {
                        
                        const allKeys = Object.keys(localStorage);
                        allKeys.forEach(value => { localStorage.removeItem(value); });
                        
                        window.location = "/home";
                    });
                }
                else {
                    window.location = "/";
                }
            });

        }, "5000")

        return (
            <Modal setIsOpen={setIsOpen} />
        )
    }

    const callback = () => {
        Manager.signinRedirectCallback().then(() => {
            window.location = "/home";
        }).catch(function (e) {
            console.log(e);
            console.error('El token es inválido');
        });

        return <h1>redirecting...</h1>;
    }

    return(
        <Router>
            <Switch>
                <Route exact path="/">
                    <Home />
                </Route>
                <Route exact path="/home">
                    <Home />
                </Route>
                <Route path="/callback">
                    {() => callback()}
                </Route>
                <Route path="/logout">
                    {() => logout()}
                </Route>
            </Switch>
        </Router>
    );
}

export default Routes;