import React, { useState } from 'react'
import Dashboard from './Dashboard';
import * as QSDB from '../helpers/constantes'

const VIEW_DESCRIPTION = "Emisión/Nómina";

const views =
{
    DATOS_GENERALES: 110,
    PERCEPCIONES: 112,
    DEDUCCIONES: 113,
    INCAPACIDADES: 114,
    JUBILACION: 115,
    INDEMNIZACION: 116,
    OTROS_PAGOS: 117
}

const descriptions =
{
    DATOS_GENERALES: "Datos generales",
    PERCEPCIONES: "Percepciones",
    DEDUCCIONES: "Deducciones",
    INCAPACIDADES: "Incapacidades",
    JUBILACION: "Jubilación",
    INDEMNIZACION: "Indemnización",
    OTROS_PAGOS: "Otros pagos"
}

const SubMenuNomina = () => {

    const [state, setState] = useState({activeView: views.DATOS_GENERALES});

    const showActiveView = () => {
        return (
            <div>
                {views.DATOS_GENERALES === state.activeView && <Dashboard title={VIEW_DESCRIPTION} subtitle={descriptions.DATOS_GENERALES} idDashboard={QSDB.NOMINA_DATOS_GENERALES}></Dashboard>}
                {views.PERCEPCIONES === state.activeView && <Dashboard title={VIEW_DESCRIPTION} subtitle={descriptions.PERCEPCIONES} idDashboard={QSDB.NOMINA_PERCEPCIONES}></Dashboard>}
                {views.DEDUCCIONES === state.activeView && <Dashboard title={VIEW_DESCRIPTION} subtitle={descriptions.DEDUCCIONES} idDashboard={QSDB.NOMINA_DEDUCCIONES}></Dashboard>}
                {views.INCAPACIDADES === state.activeView && <Dashboard title={VIEW_DESCRIPTION} subtitle={descriptions.INCAPACIDADES} idDashboard={QSDB.NOMINA_INCAPACIDADES}></Dashboard>}
                {views.JUBILACION === state.activeView && <Dashboard title={VIEW_DESCRIPTION} subtitle={descriptions.JUBILACION} idDashboard={QSDB.NOMINA_JUBILACION}></Dashboard>}
                {views.INDEMNIZACION === state.activeView && <Dashboard title={VIEW_DESCRIPTION} subtitle={descriptions.INDEMNIZACION} idDashboard={QSDB.NOMINA_INDEMNIZACION}></Dashboard>}
                {views.OTROS_PAGOS === state.activeView && <Dashboard title={VIEW_DESCRIPTION} subtitle={descriptions.OTROS_PAGOS} idDashboard={QSDB.NOMINA_OTROS_PAGOS}></Dashboard>}
            </div>);
    }

    const changeActiveView = (newActiveView) => {
        setState({ activeView: newActiveView });
    }

    return (
        <div>
            <div className="dashboardOption">
                <ul>
                    <li className=""><a href='#' onClick={() => changeActiveView(views.DATOS_GENERALES)}>{descriptions.DATOS_GENERALES}</a></li>
                    <li className=""><a href='#' onClick={() => changeActiveView(views.PERCEPCIONES)}>{descriptions.PERCEPCIONES}</a></li>
                    <li className=""><a href='#' onClick={() => changeActiveView(views.DEDUCCIONES)}>{descriptions.DEDUCCIONES}</a></li>
                    <li className=""><a href='#' onClick={() => changeActiveView(views.INCAPACIDADES)}>{descriptions.INCAPACIDADES}</a></li>
                    <li className=""><a href='#' onClick={() => changeActiveView(views.JUBILACION)}>{descriptions.JUBILACION}</a></li>
                    <li className=""><a href='#' onClick={() => changeActiveView(views.INDEMNIZACION)}>{descriptions.INDEMNIZACION}</a></li>
                    <li className=""><a href='#' onClick={() => changeActiveView(views.OTROS_PAGOS)}>{descriptions.OTROS_PAGOS}</a></li>
                </ul>
            </div>

            {showActiveView()}
        </div>
    );
}

export default SubMenuNomina;