import { UserManager } from 'oidc-client-ts';
import { AUTH_IS_URL, IS_URL, WEB_URL, API_URL, CLIENT_ID, OAUTH, JWKS, CLIENT_SECRET } from './constantes';
import axios from 'axios';

var userConfig =
{
    authority: AUTH_IS_URL,
    issuer: IS_URL,
    authorization_endpoint: `${AUTH_IS_URL}/api/${OAUTH}/authorize`,
    token_endpoint: `${AUTH_IS_URL}/api/${OAUTH}/token`,
    jwks_uri: `${AUTH_IS_URL}/.well-known/${JWKS}`,
    scope: 'openid profile',
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    redirect_uri: `${WEB_URL}/callback`,
    post_logout_redirect_uri: `${WEB_URL}/logout`,
    end_session_endpoint : `${AUTH_IS_URL}/api/oauth/logout`
};

const Manager = new UserManager(userConfig);

export default Manager;

export const GetUserInfo = async (email, access_token) => {
    try {
        const response = await axios.get(`${API_URL}/users/${email}`, {
            headers: { 'Authorization': `Bearer ${access_token}` }
        });
        return processUserInfoResponse(response, access_token);
    } catch (error) {
        console.log(`Ocurrio un error al obtener datos de usuario -> ${error}`);
        return null;
    }
};

const processUserInfoResponse = (response, access_token) => {
    switch (response.status) {
        case 200:
            return {
                access_token: access_token,
                response: response.data
            };
        case 204:
            console.log(`Respuesta no satisfactoria: ${response.status}: no se encontro información del usuario en el sistema`);
            return null;
        default:
            let messageRespo = response.statusText === undefined || response.statusText === null || response.statusText === ''  ? 'Sin descripción' : 'response.statusText' ;
            console.log(`Respuesta no satisfactoria, no se pudo obtener información de usuario verifique credenciales: ${response.status}: ${messageRespo}`);
            return null;
    }
}

export const Authorize = () => {
    Manager.getUser().then((user) => {
        if (!user)
            Manager.signinRedirect();
    });

    return true;
};